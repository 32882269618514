import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import CustomizeIconImage from "../../images/customize-icon.svg";
import FastIconImage from "../../images/fast-icon.svg";
import ReliableIconImage from "../../images/reliable-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-bold text-black text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    { imageSrc: CustomizeIconImage,
      title: "Digital Marketing",
      description: "Digital marketing-ul cuprinde un set de strategii si tactici de promovare a produselor si serviciilor prin intermediul canalelor digitale, cum ar fi motoarele de cautare, retelele sociale, email-ul si site-urile web. Acestea includ publicitatea online, marketingul de continut, SEO (optimizarea motoarelor de cautare), PPC (plata pe click)." },
    { imageSrc: FastIconImage, title: "Web Development", description: "Web development-ul pentru digital marketing implica crearea si dezvoltarea de site-uri web optimizate pentru motoarele de cautare si utilizatorii online, incluzand design responsiv, navigare usoara si integrare cu instrumente de analiza web. Un site bine optimizat poate contribui semnificativ la cresterea traficului si a conversiilor pentru o afacere online." },
    { imageSrc: ReliableIconImage, title: "Do It Yourself", description: "Pachetul Do It Yourself este ideal pentru cei care doresc sa invete cum sa optimizeze website-ul lor si sa isi dezvolte singuri strategiile de digital marketing plus multe altele. Do It Yourself ofera clientilor posibilitatea de a dobandi cunostintele si abilitatile necesare pentru a isi creste afacerea online, economisind in acelasi timp bani pe termen lung." }
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>Descopera <span tw="text-primary-500">serviciile noastre</span> </Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
